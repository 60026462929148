import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  FirebaseApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import {
  QueryClient,
  provideAngularQuery,
} from '@tanstack/angular-query-experimental';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { DOCUMENT } from '@angular/common';
import { AuthInterceptor, AuthService } from '@trueleap/auth';
import { BASE_API_URL, HOST_URL, VAPID_KEY } from '@trueleap/ui-tokens';
import { QuillModule } from 'ngx-quill';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { getSubdomain } from './util';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(withEventReplay()),
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      })
    ),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([AuthInterceptor])),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    importProvidersFrom(QuillModule.forRoot()),
    provideStorage(injector =>
      getStorage(injector.get(FirebaseApp), 'gs://trueleap-plus-dev')
    ),
    provideFirestore(injector => getFirestore(injector.get(FirebaseApp))),
    provideMessaging(injector => getMessaging(injector.get(FirebaseApp))),
    provideNativeDateAdapter(),
    // provideDateFnsAdapter(),
    provideNgxStripe(
      `pk_test_51OZb5dJ9y6udfrZfch97Sh3KLw9kpVz0vKyPOsabVhg8XdheezZGtAK9h8ou1Olh9Szob1Ba8mllCC1T5rmadoZa00XAfksICi`
    ),
    provideAuth(() => getAuth()),
    {
      provide: BASE_API_URL,
      useValue: environment.api.BASE_URL,
    },
    {
      provide: HOST_URL,
      useValue: environment.host,
    },
    provideAngularQuery(new QueryClient()),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthService, DOCUMENT],
      useFactory: (authService: AuthService, document: Document) => {
        return () => {
          const subdomainName =
            getSubdomain(document.location.hostname || '') || '';
          authService.setSubDomain(subdomainName);
          return authService.getSignedInUser();
        };
      },
    },
    {
      provide: VAPID_KEY,
      useValue: environment.vapIdKey,
    },
  ],
};

import { parse } from 'tldts';
import { environment } from '../environments/environment';

export const getSubdomain = (host: string) => {
  const { subdomain } = parse(host);
  if (!environment.production) {
    return subdomain;
  }

  const [subdomainName] =
    (subdomain?.includes('.') && subdomain?.split('.')) || [];
  return subdomainName;
};

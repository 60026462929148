export const environment = {
  host: 'trueleap-plus.trueleap.io',
  firebase: {
    apiKey: 'AIzaSyDYqyooacw-9N7Z0clsAQwbMte4RxNkdXI',
    authDomain: 'meta-strata-343416.firebaseapp.com',
    projectId: 'meta-strata-343416',
    storageBucket: 'meta-strata-343416.appspot.com',
    messagingSenderId: '454653205973',
    appId: '1:454653205973:web:695f33fb1f4ede5c5a315c',
    measurementId: 'G-PBHF39JZV6',
  },
  production: true,
  SYNCFUSION_LICENSE:
    'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdeeHRVQ2heWEN0WUI=',

  vapIdKey: 'BNuXajUhL_InmElzIIejNvLnFuZ3mcZuqvbi9cHGRy4kgFKgtA',
  DEFAULT_LANGUAGE: 'en',
  MIXPANEL_TOKEN: '981b030af3ee0b61de820e41f0e8288f',
  HOST_URI: 'trueleap-plus.trueleap.io',
  api: {
    BASE_URL: 'https://api-dev-tp.trueleap.io/api',
  },
};

import { inject } from '@angular/core';
import { CanActivateFn, Route, Router } from '@angular/router';
import {
  LandingComponent,
  LandingHome,
} from '@trueleap-plus/features/landing-page';
import {
  AuthContainerComponent,
  AuthStore,
  LoginComponent,
  PasswordResetComponent,
  SignUpComponent,
} from '@trueleap/auth';

export const isWorkSpaceUser: CanActivateFn = () => {
  const authStore = inject(AuthStore);
  const user = authStore.profile;
  const currentOrg = authStore.currentOrg;
  const router = inject(Router);
  if (!user() || !currentOrg()) {
    return router.parseUrl('/');
  }
  return true;
};

export const canSeeLandingPage: CanActivateFn = async () => {
  const authStore = inject(AuthStore);
  const subDomain = authStore.currentSubDomain;
  const router = inject(Router);
  if (subDomain()) {
    return router.parseUrl('/courses');
  }
  return true;
};

export const notLoggedIn: CanActivateFn = () => {
  const user = inject(AuthStore).profile;
  const router = inject(Router);
  if (user()) {
    return router.parseUrl('/');
  }

  return true;
};

export const appRoutes: Route[] = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        component: LandingHome,
        canActivate: [canSeeLandingPage],
      },
      {
        path: 'courses',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.PublicCourseList),
      },
      {
        path: 'courses/:id',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.PublicCourseDetail),
      },
      {
        path: 'contents',
        loadComponent: () =>
          import('@trueleap-plus/content').then(m => m.ContentComponent),
      },
      {
        path: 'contents/:id',
        loadComponent: () =>
          import('@trueleap-plus/content').then(m => m.ContentComponent),
      },
      {
        path: 'events',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.PublicWebinerComponent
          ),
      },
      {
        path: 'events/:id',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.PublicWebinerDetailsComponent
          ),
      },
      {
        path: 'my-courses',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.MyCoursesComponent),
      },
      {
        path: 'my-events',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.MyWebinerComponent
          ),
      },
    ],
  },
  {
    path: 'payment',
    loadChildren: () => import('@trueleap/payment').then(m => m.PaymentModule),
  },
  {
    path: 'auth',
    component: AuthContainerComponent,
    canActivate: [notLoggedIn],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
      {
        path: 'reset-password',
        component: PasswordResetComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('@trueleap-plus/ui/dashboard').then(m => m.DashboardComponent),
    // canActivate: [isWorkSpaceUser],
    children: [
      {
        path: 'analytics',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.AnalyticsComponent),
        data: {
          breadcrumb: {
            label: 'Analytics',
            info: { icon: 'analytics' },
          },
          routes: [],
        },
      },
      {
        path: 'analytics/',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.AnalyticsComponent),
        data: {
          breadcrumb: {
            label: 'Analytics',
            info: { icon: 'analytics' },
          },
          routes: [],
        },
      },
      {
        path: 'content-management',
        loadChildren: () =>
          import('@trueleap/course').then(m => m.CourseRoutes),
        data: {
          breadcrumb: {
            label: 'Content Management',
            info: { icon: 'library_books' },
          },
          routes: [
            {
              label: 'All Courses',
              icon: 'library_books',
              route: '/dashboard/content-management/courses',
            },
            {
              label: 'Create Course',
              icon: 'add_circle',
              route: '/dashboard/content-management/courses/create',
            },
            {
              label: 'All Contents',
              icon: 'video_library',
              route: '/dashboard/content-management/contents',
            },
            {
              label: 'Create Content',
              icon: 'add_circle',
              route: '/dashboard/content-management/contents/create',
            },
          ],
        },
      },
      {
        path: 'events',
        loadChildren: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.featuresWebinerRoutes
          ),
        data: {
          routes: [
            {
              label: 'All Events',
              icon: 'list',
              route: '/dashboard/events/list',
            },
            {
              label: 'Create Event',
              icon: 'add_circle',
              route: '/dashboard/events/create',
            },
            {
              label: 'Events Recordings',
              icon: 'videocam',
              route: '/dashboard/events/recordings',
            },
          ],
          breadcrumb: {
            label: 'Events',
            info: { icon: 'event' },
          },
        },
      },
      {
        path: '',
        redirectTo: 'content-management',
        pathMatch: 'full',
      },
      // {
      //   path: 'home',
      //   loadComponent: () =>
      //     import('@trueleap-plus/ui/dashboard').then(m => m.DashboardHome),
      // },
      {
        path: 'my-purchases',
        loadComponent: () =>
          import('@trueleap-plus/ui/my-purchase').then(
            m => m.MyPurchaseComponent
          ),
      },
      {
        path: 'lms',
        loadChildren: () =>
          import('@trueleap-plus/ui/lms').then(m => m.LmsRoutes),
        data: {
          breadcrumb: {
            label: 'LMS',
            info: { icon: 'school' },
          },
        },
      },
      {
        path: 'my-files/',
        loadComponent: () =>
          import('@trueleap-plus/ui/dashboard').then(m => m.MyFiles),
        data: {
          breadcrumb: {
            label: 'My Files',
            info: { icon: 'folder' },
          },
        },
      },

      {
        path: 'users',
        loadChildren: () => import('@trueleap/users').then(m => m.ROUTES),
        data: {
          breadcrumb: {
            label: 'Users',
            info: { icon: 'people' },
          },
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('@trueleap-plus/profile').then(m => m.PROFILE_ROUTES),
        data: {
          breadcrumb: {
            label: 'Profile',
            info: { icon: 'person' },
          },
        },
      },

      {
        path: 'purchases/my-courses',
        loadComponent: () =>
          import('@trueleap/course').then(m => m.MyCoursesComponent),
      },
      {
        path: 'purchases/my-events',
        loadComponent: () =>
          import('@trueleap-plus/features/webiner').then(
            m => m.MyWebinerComponent
          ),
      },

      {
        path: '**',
        loadComponent: () =>
          import('@trueleap/ui-kit').then(m => m.NotFoundComponent),
        data: {
          breadcrumb: {
            label: 'Page not found',
            info: { icon: 'error' },
          },
          routes: [],
        },
      },
    ],
    data: {
      breadcrumb: {
        label: 'Home',
        info: { icon: 'home' },
      },
    },
  },
];
